import React, { useEffect } from "react";
import { Button, Avatar, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import useClasses from "components/common/useClasses";
import { Controller } from "react-hook-form";

const themeStyles = (themeV5) => ({    
  mr2: {
    marginRight: themeV5.spacing(2)
  }
}
);

const defaultStyles = {
  container: {
    spacing: 2, 
    justifyContent: "center",
    alignItems: "center"
  },
  avatarSx: { 
    width: 75, 
    height: 75 
  }
};

const AvatarUpload = ({ pictureFileName, pictureSourceName, 
          useFormHook, styles = defaultStyles, avatarAlt='picture', 
          buttonLabel='  Upload', disabled=false } ) => {

  const classes = useClasses(themeStyles);
  const [isDisabled] = React.useState(disabled);

  const useDisplayPicture = () => {
    const [src, setSrc] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const displayPicture = (e) =>  {
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setSrc(e.target.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      setFile(e.target.files[0]);
    }
    return { src, file, displayPicture };
  }

  const { src, file, displayPicture } = useDisplayPicture();

  React.useEffect(() => {
    if(src){
      useFormHook.setValue(pictureFileName, file);
      useFormHook.setValue(pictureSourceName, src);
    }
  }, [src]);

  return (
    <Stack spacing={styles.container.spacing} 
            justifyContent={styles.container.justifyContent}
            alignItems={styles.container.alignItems}>

      <Controller
        name={ pictureSourceName }
        control={useFormHook.control}
        render={({ field }) => <Avatar {...field} src={field.value} alt={avatarAlt} sx={styles.avatarSx} />}
      />

      <Stack>
        <label htmlFor="upload-picture" >
          <input id="upload-picture" name="picture"
              style={{ display: "none" }} disabled={isDisabled}
              type="file" accept="image/*"
              onChange={(e) => {
                displayPicture(e);
              }}            
          />
          <Button color="primary" variant="outlined" component="span" disabled={isDisabled}>
            <FontAwesomeIcon icon={faUpload} className={classes.mr2}></FontAwesomeIcon>{"  "}{buttonLabel}
          </Button>
        </label>
      </Stack>
      
    </Stack>
  );
}

export { AvatarUpload };
