
import React from "react";

import { Button, Card, CardContent, CardHeader, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useIntl } from "gatsby-plugin-intl";
import { navigate } from "gatsby";


import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { RhfListOfValuesSelect, RhfTextField } from "../common/RhfFields";
import userService from "services/api/userService";
import themeMuiV5 from "components/common/ThemeMuiV5";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import { GROUP } from "utils/constants/back-office-constants";

const AddUserPage = ({}) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const styles = {
    gridContainer: {
        rowSpacing: 1,
        columnSpacing: 1,
        style: {
            width: "100%", 
            height: "100%",
            margin: "0px 0px 0px",
            padding: "0px 0px 0px"
        }    
    },
    gridItem: {
        rowSpacing: 0,
        columnSpacing: 0,
        style: {
            //border: "1px solid green",
            width: "100%", 
            height: "100%",
            margin: "0px 0px 0px",
            padding: "0px 8px 0px",
        }
    },
    textFields: {
      style: {
          width: isMobile ? "100%" : "75%"
      }
    },
    universitySx: {
      width: isMobile ? "100%" : "75%"
    },
    typoUserName: {
        color: "grey",
        fontWeight: 400
    },
    typoTitle: {
        color: "grey",
        fontWeight: 400
    },
  }

  const [resultMessage, setResultMessage] = React.useState("");
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);

  const userDefaultValues = {
    firstName: '',
    universityId: null,
    email: '',
    lastName: ''
  };

  const validateUserToCreate = yup.object().shape({
      firstName: yup.string().required("error.field.required"),
      lastName: yup.string().required("error.field.required"),
      email: yup.string().email("error.email.invalid")
                  .required("error.field.required"),
      universityId: yup.string().required("error.field.required")
  });

  const useFormHook = useForm(
    {
        resolver: yupResolver(validateUserToCreate),
        defaultValues: userDefaultValues,
        mode: "onChange",
    }
  );
  
  const {
      reset,
      control,
      formState: { errors, isDirty },
  } = useFormHook;

  
  const handleSaveUser = async (user) => {
    setDisableSubmit(true);
    setOpenSuccess(false);
    setOpenError(false);
    const userInfos = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        universityId: user?.universityId
    };
    userService
        .createUser(userInfos)
        .then((result) => {
            setResultMessage("backoffice.users.create.success");
            setOpenSuccess(true);
            setDisableSubmit(false);
            navigate('/' + intl.locale + '/back-office/users');
        })
        .catch((error) => {
            handleCallApiError(error, "backoffice.users.create.error");
        });
  }

  const handleCallApiError = (error, errorLabel) => {
    if (error && error.data && error.data.errorCode) {
        errorLabel = error.data.errorCode;
    }
    let errorMessage = intl.formatMessage({id: errorLabel});
    console.error(errorMessage, error);

    if(error?.data?.parameters){
      console.error("errors:", error?.data?.parameters);
      errorMessage = errorMessage + ' : ' + error?.data?.parameters.join(',');  
    }
    setResultMessage(errorMessage);
    setDisableSubmit(false);
    setOpenError(true);
  }

  return (
    <>
      <LayoutBo>

        <Card sx={{
            backgroundColor: themeMuiV5.palette.background.paper,
            height: "100%",
            paddingTop: themeMuiV5.spacing(1),
            color: themeMuiV5.palette.primary.main
        }}>

          <CardHeader sx={{
                backgroundColor: themeMuiV5.palette.primary.main,
                textAlign: "center",
                paddingTop: themeMuiV5.spacing(1),
                color: themeMuiV5.palette.primary.contrastText
            }}
            title={ intl.formatMessage({id: "backoffice.users.create.title"}) }
          />

          <CardContent sx={{
            height: "100%",
            backgroundColor: themeMuiV5.palette.background.paper
            }}
          >

            <form onSubmit={useFormHook.handleSubmit(handleSaveUser)} noValidate>

            <ResultSnackbars
                openError={openError}
                openSuccess={openSuccess}
                messageStr={resultMessage}
                setOpenSuccess={setOpenSuccess}
                setOpenError={setOpenError}
            />  

            <Grid container 
              columnSpacing={ styles.gridContainer.columnSpacing }
              rowSpacing={ styles.gridContainer.rowSpacing } 
              style={ styles.gridContainer.style }
            >

              <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }}>
                  <RhfTextField 
                      id="lastName"
                      name={"lastName"} 
                      textTransform="uppercase"
                      label={"backoffice.users.create.lastName"} 
                      style={styles.textFields.style}
                      control={useFormHook.control}
                      error={useFormHook.formState.errors ? useFormHook.formState.errors.lastName : null}
                      defaultFocus={true}
                  />
              </Grid>

              <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }}>
                  <RhfTextField 
                      id="firstName"
                      name={"firstName"} 
                      textTransform="capitalize"
                      label={"backoffice.users.create.firstName"} 
                      style={styles.textFields.style}
                      control={useFormHook.control}
                      error={useFormHook.formState.errors ? useFormHook.formState.errors.firstName : null}
                  />
              </Grid>

              <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }}>
                  <RhfTextField
                      id="email"
                      name="email"
                      label={"backoffice.users.create.email"} 
                      style={styles.textFields.style}
                      control={useFormHook.control}
                      error={useFormHook.formState.errors ? useFormHook.formState.errors.email : null}
                  />
              </Grid>

              <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "left" }}>
                <RhfListOfValuesSelect
                      name="universityId"
                      label={"backoffice.users.create.university"}
                      sx={styles.universitySx}
                      group={GROUP.university}
                      control={useFormHook.control}
                      error={useFormHook.formState.errors ? useFormHook.formState.errors.universityId : null}
                />
              </Grid>

            </Grid>

            <Stack direction="row" spacing={5} margin={5} justifyContent={"center"}>
              
              <Button color="primary" variant="contained" type="submit" disabled={disableSubmit}>
                  {intl.formatMessage({id: "backoffice.users.edit.saveButton"})}
              </Button>

              <ConfirmationDialog listingUrl={`/back-office/users`} />

            </Stack> 

            </form>
              
          </CardContent>
          
        </Card>                      
      </LayoutBo>    
    </>
    );
}

export default AddUserPage;