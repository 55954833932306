import React, {Fragment, useState} from "react";

import {IconButton, Snackbar} from "@mui/material";
import { Alert } from '@mui/material';

import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useIntl} from "gatsby-plugin-intl";

const ResultSnackbars = ({ openSuccess, openError, messageStr = null, messageId = null, setOpenSuccess, setOpenError }) => {
    const intl = useIntl();
    const [state, setState] = useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;
    
    return (<>
      {openSuccess && (
        <Snackbar open={openSuccess} autoHideDuration={6000}
                  anchorOrigin={{ vertical, horizontal }}
                  key={"success_"+vertical + horizontal}
                  onClose={() => setOpenSuccess(false)}>
          <Alert
            severity="success"
            onClose={() => setOpenSuccess(false)}
            action={
              <IconButton
                aria-label="close success"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenSuccess(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
              </IconButton>
            }
          >
            { messageId && intl.formatMessage({ id: messageId }) }
            { messageStr && messageStr }
          </Alert>
        </Snackbar>
      )}
      {openError && (
        <Snackbar open={openError}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical, horizontal }}
                  key={"error_ "+vertical + horizontal}
                  onClose={() => setOpenError(false)}

        >
          <Alert
            severity="error"
            onClose={() => setOpenError(false)}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenError(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
              </IconButton>
            }
          >
            { messageId && intl.formatMessage({ id: messageId }) }
            { messageStr && messageStr }
          </Alert>
        </Snackbar>
      )}
    </>);
};

export default ResultSnackbars;
