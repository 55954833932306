
import React from "react";

import { Button, FormLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import {useFieldArray} from "react-hook-form";
import {useIntl} from "gatsby-plugin-intl";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";

import {GROUP} from "utils/constants/back-office-constants";
import { RhfListOfValuesSelect, RhfTextField } from "../../../common/RhfFields";
import { DISABLED_CONDITION } from "utils/courses-schema";


const MultiLinkSelect = ({ control, name, register, courseType }) => {
    
    const intl = useIntl();
    const {
        fields: linkFields,
        append: linkAppend,
        remove: linkRemove,
    } = useFieldArray({name: name, control});

    const linksList =  linkFields.map((link, i) => (  
        <Grid item key={link.id} style={{ width: '100%' }}
            //style={{ width: '100%', border: i % 2 === 0 ? '2px solid blue' : '1px solid red' }}
        >   
            <Grid container spacing={3}>
                <input type="hidden" {...register(`${name}.${i}.linkId`)}></input>            
                <Grid item xs={12} md={3}>
                    <RhfListOfValuesSelect
                        id={`${name}-type-${i}-select`}
                        label={"links.type"}
                        name={name + `.${i}.type`}
                        defaultValue={link.type}
                        group={GROUP.linksType}
                        fullWidth
                        control={control}                    
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <RhfTextField
                        id={`${name}-description-${i}-text`}
                        label="links.description"
                        name={name + `[${i}].description`}
                        defaultValue={link.description}
                        fullWidth
                        control={control}                    
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <RhfTextField
                        id={`${name}-url-${i}-text`}
                        label="links.url"
                        name={name + `[${i}].url`}
                        defaultValue={link.url}
                        fullWidth
                        control={control}
                    />
                </Grid>
                <Grid item xs={12} md={1}>
                    <Grid container direction='column' height='100%' alignContent={'start'} justifyContent={'end'}>
                        <Grid item>
                            <Button
                                onClick={() => {
                                        if (linkFields.length === 1) {
                                            linkRemove(i);
                                            linkAppend({ linkId: "", description: "", type: "", url: "" });
                                        } else {
                                            linkRemove(i);
                                        }
                                    }}
                                title={intl.formatMessage({id: "links.delete"})}
                                color="secondary"
                            >
                                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                            </Button>
                        </Grid>

                        {linkFields.length - 1 === i && (
                            <Grid item>

                                <Button
                                    color="primary"
                                    onClick={() => linkAppend({description: "", type: "", url: ""})}
                                    title={intl.formatMessage({id: "links.add"})}
                                >
                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>  
        </Grid>            
    ));

    return (
        <>
            <FormLabel component="legend" required={DISABLED_CONDITION["course.links"].required(courseType)}>
                {intl.formatMessage({ id: "course.links" })}
            </FormLabel>
            <Grid container>
                { linksList }                     
            </Grid>            
        </>        
    );
};

export default MultiLinkSelect;