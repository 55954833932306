import React, { Fragment } from "react";

import { Box, Button, Card, CardHeader, FormHelperText, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CardContent from "@mui/material/CardContent";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useBeforeunload} from "react-beforeunload";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {faTimes, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import "draft-js/dist/Draft.css";

import { navigate, useIntl} from "gatsby-plugin-intl";

import newsService from "services/api/newsService";
import {GROUP} from "utils/constants/back-office-constants";
import {INPUT_MAX_LENGTH} from "utils/constants/constants";
import {FIELD_CONDITION, opportunitySchema,} from "utils/opportunities-schema";
import Seo from "components/common/Seo";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import { RhfListOfValuesCheckboxes, RhfListOfValuesSelect, RhfSwitch, RhfTextField } from 'components/backoffice/common/RhfFields';
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import {OPPORTUNITY_TYPE} from "utils/constants/back-office-constants";
import { parseISO } from 'date-fns';
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import RhfDatePicker from "ui/RhfDatePicker";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  textField: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: "24ch",
  },
  formControlLabel: {
    verticalAlign: "bottom",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(8),
  },
  editorClass: {
    border: "1px solid #ccc",
    minHeight: 200,
  },
  mr1: {
    marginRight: theme.spacing(2),
  },
  toolbarClass: {
    backgroundColor: `${theme.palette.grey.main}!important`,
  },
  fileClass: {
    color: theme.palette.primary.main,
  },
  marginLeft: {
    marginLeft: theme.spacing(3),
  },
  card: {
    maxHeight: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  cardContent: {
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const AddEditNewsPage = ({ opportunityId }) => {

  const intl = useIntl();
  const classes = useStyles();
  const defaultValues = React.useRef({
    type: "",
    coverImage: "",
    title: "",
    hook: "",
    keyword: "",
    link: "",
    startDate: new Date(),
    endDate: new Date(),
    visible: true,
    publicProfile: [],
    publicCycle: [],
    deleteImage: false,
    fileId: "",
    deadline: null,
  });

  const methods = useForm(
    {
      resolver: yupResolver(opportunitySchema),
      defaultValues: defaultValues.current,
      mode: "onChange",
    },
    []
  );

  const {
    reset,
    control,
    formState: { errors, isDirty },
  } = methods;

  const updatedOpportunityId = React.useRef(opportunityId);

  const EditorReact = require("react-draft-wysiwyg").Editor;


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await newsService.searchById(opportunityId);
        const data = result.data;
        data.startDate = parseISO(data.startDate);
        data.endDate = parseISO(data.endDate);
        data.deadline = parseISO(data.deadline);
        setCoverImageName(data.coverImageName);
        const newDefaultValues = Object.assign({}, defaultValues.current, data);
        reset(newDefaultValues);
        if (data.content) {
          setEditorState(
            EditorState.createWithContent(
              // convertFromRaw(markdownToDraft(data.content))
              convertFromRaw(JSON.parse(data.content))
            )
          );
        }
      } catch (error) {
        navigate("/back-office/opportunities");
      }
    };

    if (!!opportunityId) {
       fetchData();
     
    }
  }, [opportunityId, reset, defaultValues]);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
    // editorStateTest
  );

  useBeforeunload((event) => {
    if (isDirty || editorDirty) {
      event.preventDefault();
      event.returnValue = "";
    }
  });

  const [coverImageName, setCoverImageName] = React.useState("");
  const [resultMessage, setResultMessage] = React.useState("");
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [editorDirty, setEditorDirty] = React.useState(false);
  const [textEmpty, setTextEmpty] = React.useState(false);

  const onEditorStateChange = (editorState) => {
    if (editorState.getCurrentContent().hasText() || type === OPPORTUNITY_TYPE.alert) {
      setTextEmpty(false);
    }else{
      setTextEmpty(true);
    }
    setEditorState(editorState);
    setEditorDirty(true);
  };

  const coverImage = methods.watch("coverImage");
  const publicProfile = methods.watch("publicProfile");
  const type = methods.watch("type");

  const submitForm = () => {
    if (editorState.getCurrentContent().hasText() || type === OPPORTUNITY_TYPE.alert) {
      setTextEmpty(false);
    }else{
      setTextEmpty(true);
      return;
    }
    setDisableSubmit(true);
    setOpenSuccess(false);
    setOpenError(false);
    if (!FIELD_CONDITION["content"].disabled(type)) {
      if (
        methods.getValues("link") === "" &&
        !editorState.getCurrentContent().hasText()
      ) {
        methods.setError("link", {
          type: "manual",
          message: "error.field.required",
        });
      }
    }
    resetDisabledField();
    const content = convertToRaw(editorState.getCurrentContent());
    //  const markdownString = draftToMarkdown(content);
    const contentString = editorState.getCurrentContent().hasText()? JSON.stringify(content): null;
   
    if (!!updatedOpportunityId.current) {
      newsService
        .updateOpportunity(
          updatedOpportunityId.current,
          methods.getValues(),
          contentString
        )
        .then(() => {
          setResultMessage("opportunity.update.success");
          setOpenSuccess(true);
          setDisableSubmit(false);
          if (
            methods.getValues("coverImage") &&
            methods.getValues("coverImage")[0]
          ) {
            setCoverImageName(methods.getValues("coverImage")[0].name);
          }
          methods.reset(methods.getValues());
          setEditorDirty(false);
          methods.setValue("deleteImage", false);
        })
        .catch((error) => {
          setResultMessage("opportunity.update.error");
          setOpenError(true);
          setDisableSubmit(false);
          methods.setValue("deleteImage", false);
        });
    } else {
      newsService
        .saveOpportunity(methods.getValues(), contentString)
        .then((result) => {
          updatedOpportunityId.current = result.data;
          setResultMessage("opportunity.create.success");
          setOpenSuccess(true);
          setDisableSubmit(false);
          if (
            methods.getValues("coverImage") &&
            methods.getValues("coverImage")[0]
          ) {
            setCoverImageName(methods.getValues("coverImage")[0].name);
          }
          methods.reset(methods.getValues());
          setEditorDirty(false);
          methods.setValue("deleteImage", false);
        })
        .catch((error) => {
          setResultMessage("opportunity.create.error");
          setOpenError(true);
          setDisableSubmit(false);
          methods.setValue("deleteImage", false);
        });
    }
  };

  const fieldsDisabled = ["content", "hook", "coverImage", "keyword", "link"];

  const resetDisabledField = () => {
    //prevent disabled fields from being sending on submit
    fieldsDisabled.forEach((item) => {
      if (FIELD_CONDITION[item].disabled(type)) {
        methods.setValue(item, "");
      }
    });
  };

  const deleteImage = () => {
    methods.setValue("coverImage", "");
    setCoverImageName("");
    methods.setValue("deleteImage", true);
  };

  return (
    <LayoutBo>
      <Seo
        title={intl.formatMessage({ id: "opportunity.edit" })}
        lang={intl.locale}
      />
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            !!opportunityId
              ? intl.formatMessage({ id: "opportunity.edit" })
              : intl.formatMessage({ id: "opportunity.new" })
          }
        />
        <CardContent className={classes.cardContent}>
          <ResultSnackbars
            openError={openError}
            openSuccess={openSuccess}
            messageId={resultMessage}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
          />
          <form noValidate onSubmit={methods.handleSubmit(submitForm)}>
            <Grid container spacing={3}>
              <Grid item xl={3} md={4} sm={12}>
                <RhfListOfValuesSelect
                  name="type"
                  label={"opportunity.type"}
                  group={GROUP.opportunityType}
                  control={methods.control}
                  className={classes.textField}
                  required
                  id="opportunity-type-select"
                  error={errors ? errors.type : null}
                />
              </Grid>
              <Grid item xl={9} md={8} sm={12}>
                <RhfTextField
                  label="opportunity.title"
                  name="title"
                  control={control}
                  required={true}
                  fullWidth
                  id="opportunity-title-text"
                  error={errors ? errors.title : null}
                  maxLength={INPUT_MAX_LENGTH.NEWS_TITLE}
                ></RhfTextField>
              </Grid>
              <Grid item xl={3} md={4} sm={12}>
                <RhfTextField
                  label="opportunity.hook"
                  name="hook"
                  className={classes.textField}
                  control={control}
                  id="opportunity-hook-text"
                  disabled={FIELD_CONDITION["hook"].disabled(type)}
                  error={errors ? errors.hook : null}
                ></RhfTextField>
              </Grid>
              <Grid item xl={9} md={8} sm={12}>
                <RhfListOfValuesSelect
                  name="keyword"
                  label={"opportunity.keyword"}
                  group={GROUP.keyword}
                  control={methods.control}
                  className={classes.textField}
                  disabled={FIELD_CONDITION["keyword"].disabled(type)}
                  id="opportunity-keyword-select"
                  error={errors ? errors.keyword : null}
                />
              </Grid>
              <Grid item xl={3} md={4} sm={12}>

                <RhfDatePicker
                  name="startDate"
                  label="opportunity.start.date"
                  required={true}
                  format="dd/MM/yyyy" 
                  control={methods.control}           
                />
              </Grid>
              <Grid item xl={3} md={4} sm={12}>
                <RhfDatePicker
                  name="endDate"
                  label="opportunity.end.date"
                  required={true}
                  format="dd/MM/yyyy"    
                  control={methods.control}        
                />
              </Grid>
              <Grid item xl={6} md={4} sm={12}>
                <RhfSwitch
                  label="opportunity.visible"
                  name="visible"
                  // className={classes.formControlLabel}
                  control={methods.control}
                />
              </Grid>
              <Grid item xl={3} md={4} xs={12}>
                <RhfListOfValuesCheckboxes
                  name="publicProfile"
                  label="opportunity.public.profile"
                  className={classes.formControl}
                  getValues={methods.getValues}
                  control={methods.control}
                  group={GROUP.roleGroup}
                  required={true}
                  error={errors ? errors.publicProfile : null}
                />
              </Grid>
              <Grid item xl={9} md={4} xs={12}>
                <RhfListOfValuesCheckboxes
                  name="publicCycle"
                  label="opportunity.public.cycle"
                  className={classes.formControl}
                  getValues={methods.getValues}
                  control={methods.control}
                  group={GROUP.role}
                  disabled={
                    publicProfile &&
                    publicProfile.filter((item) => item.code === "STUDENT")
                      .length === 0
                  }
                  required={true}
                  error={errors ? errors.publicCycle : null}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="upload-cover-image" className={classes.mr1}>
                  <input
                    style={{ display: "none" }}
                    id="upload-cover-image"
                    name="coverImage"
                    type="file"
                    disabled={FIELD_CONDITION["coverImage"].disabled(type)}
                    {...methods.register("coverImage", { required: false })}
                  />
                  <Button
                    color="primary"
                    variant="outlined"
                    component="span"
                    disabled={FIELD_CONDITION["coverImage"].disabled(type)}
                  >
                    <FontAwesomeIcon
                      icon={faUpload}
                      className={classes.mr1}
                    ></FontAwesomeIcon>{" "}
                    Upload Cover image
                  </Button>
                </label>
                {((coverImage && coverImage.length > 0) || coverImageName) && (
                  <span className={classes.fileClass}>
                    {coverImage && coverImage.length > 0
                      ? coverImage[0].name
                      : coverImageName
                      ? coverImageName
                      : null}
                    <IconButton
                      aria-label="deleteImage"
                      color="primary"
                      size="small"
                      onClick={deleteImage}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                  </span>
                )}
              </Grid>
              <Grid item sm={12}>
                <RhfTextField
                  label="opportunity.link"
                  name="link"
                  className={classes.textField}
                  control={control}
                  id="opportunity-link-text"
                  disabled={FIELD_CONDITION["content"].disabled(type)}
                  error={errors ? errors.hook : null}
                ></RhfTextField>
              </Grid>
              <Grid item sm={12}>
                <Typography component={"span"} variant={"body2"}>
                  {
                    (textEmpty) ? (
                      <Fragment>
                        <EditorReact
                          editorState={editorState}
                          editorStyle={{ fontFamily: "Verdana", borderWidth: "1", borderColor: "#d32f2f", borderStyle: "solid" }}
                          toolbar={{
                            fontFamily: {
                              options: [
                                "Arial",
                                "Georgia",
                                "Impact",
                                "Nunito Sans",
                                "Tahoma",
                                "Times New Roman",
                                "Verdana",
                              ],
                            },
                          }}
                          editorClassName={classes.editorClass}
                          toolbarClassName={classes.toolbarClass}
                          onEditorStateChange={onEditorStateChange}
                        />
                        <FormHelperText error={true}> {intl.formatMessage({ id: "error.field.required" })}</FormHelperText>
                      </Fragment>
                    ) : <EditorReact
                      editorState={editorState}
                      editorStyle={{ fontFamily: "Verdana"}}
                      toolbar={{
                        fontFamily: {
                          options: [
                            "Arial",
                            "Georgia",
                            "Impact",
                            "Nunito Sans",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                          ],
                        },
                      }}
                      editorClassName={classes.editorClass}
                      toolbarClassName={classes.toolbarClass}
                      onEditorStateChange={onEditorStateChange}
                    />
                  }
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <RhfDatePicker
                  name="deadline"
                  label="opportunity.deadline.date"
                  clearable={true}
                  methods={methods}
                  disabled={FIELD_CONDITION["deadline"].disabled(type)} 
                  format="dd/MM/yyyy"
                  control={methods.control}
                  error={errors ? errors.deadline : null}    
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Box py={3}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={disableSubmit}
                >
                  {intl.formatMessage({ id: "opportunity.save" })}
                </Button>
                <ConfirmationDialog
                  isModified={isDirty || editorDirty}
                  listingUrl="/back-office/opportunities"
                />
              </Box>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </LayoutBo>
  );
};

export default AddEditNewsPage;
